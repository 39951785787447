import { map, uniqueId } from "lodash";
import React from "react";
import {
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Hidden,
  Checkbox,
  IconButton,
  InputBase,
  DialogActions,
  Button,
  Chip,
  LinearProgress
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { deepOrange } from "@material-ui/core/colors";
import { useDispatch, useSelector } from "react-redux";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DownloadIcon from "@material-ui/icons/GetApp";
import { useConfirm } from "components/confirm";

import { createNCPDFs, getZipUrl, createZipUrl, cancelZipListen } from "actions";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    alignItems: "center",
    marginRight: theme.spacing(-1),
  },
  paper: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0,
    display: "flex",
    height: 42,
  },
  report: {
    fontSize: ".9rem",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 42,
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  wo: {
    fontSize: ".7rem",
  },
  avatar: {
    height: 26,
    width: 26,
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  checkbox: {
    padding: 0,
  },
  searchField: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 0,
  },
  asset: {
    // height: 45
  },
}));

const DownloadDialog = ({ open, onClose, reports }) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [zipId, setZipId] = React.useState('');
  const zipUrl = useSelector(state => state.zipUrls || {})
  React.useEffect(() => {
    setZipId(uniqueId(Date.now()))
    dispatch(getZipUrl(zipId))
  }, [open])
  const handleClose = () => {
    dispatch(cancelZipListen());
    onClose()
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create/Download NC Reports</DialogTitle>
      <DialogContent>
        <Typography>Create or Download Nonconformance Reports for:</Typography>
          {map(reports, (report, reportId) => (
            <Chip key={reportId} label={reportId} variant={report && report.pdfURL ? "default" : "outlined"} color="primary"/>
          ))}
          {zipUrl[zipId]
          ?<Alert severity={zipUrl[zipId].zipFile ? "success" : "warning"}>
            {zipUrl[zipId].working && <LinearProgress />}
            {zipUrl[zipId].zipFile 
              ?  <Button component='a' href={zipUrl[zipId].zipFile} variant="contained">Download Zip</Button>
              : 'Zip file being created, do not close this dialog'
            }
          </Alert>
          :null}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            confirm().then(() => dispatch(createZipUrl(zipId, reports)));
          }}
        >
          Download Report Zip
        </Button>
        <Button
          onClick={() => {
            confirm({message: 'Do you want to create PDF reports for all reports above?'}).then(() => dispatch(createNCPDFs(reports)));
          }}
        >
          Create Reports
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ItemRow = ({
  filterCriteria,
  setFilterCriteria,
  allChecked,
  handleCheckAll,
  checked,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { date, report, asset, status, workOrder, location } = filterCriteria;
  const toggleOpen = () => setOpen(prev => !prev);
  // const displayDate = `${date.slice(5, 7)}/${date.slice(8, 10)}/${date.slice(
  //   0,
  //   4
  // )}`;
  return (
    <div className={classes.root}>
      <Paper elevation={1} square className={classes.paper}>
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          className={classes.checkbox}
          onChange={handleCheckAll}
          checked={allChecked}
        />
        <Grid container spacing={2} style={{ marginTop: 0, marginBottom: 0 }}>
          <Grid item xs={4} sm={2} className={classes.center}>
            <Paper
              elevation={2}
              className={classes.asset}
              variant="outlined"
              square
            >
              <InputBase
                value={asset}
                className={classes.searchField}
                onInput={(e) => {
                  e.persist();
                  setFilterCriteria((prev) => ({
                    ...prev,
                    asset: e.target.value,
                  }));
                }}
              />
            </Paper>
          </Grid>
          <Hidden only="xs">
            <Grid item sm={2} className={classes.center} zeroMinWidth>
              <Typography variant="button">
                <Paper
                  elevation={2}
                  className={classes.asset}
                  variant="outlined"
                  square
                >
                  <InputBase
                    value={report}
                    className={classes.searchField}
                    onChange={(e) => {
                      e.persist();
                      setFilterCriteria((prev) => ({
                        ...prev,
                        report: e.target.value,
                      }));
                    }}
                  />
                </Paper>
              </Typography>
            </Grid>
          </Hidden>
          <Grid item item xs={4} sm={2} className={classes.center}>
            <Paper
              elevation={2}
              className={classes.asset}
              variant="outlined"
              square
            >
              <InputBase
                value={status}
                className={classes.searchField}
                onChange={(e) => {
                  e.persist();
                  setFilterCriteria((prev) => ({
                    ...prev,
                    status: e.target.value,
                  }));
                }}
              />
            </Paper>
          </Grid>
          <Hidden only="xs">
            <Grid item sm={2} className={classes.center}>
              <Paper
                elevation={2}
                className={classes.asset}
                variant="outlined"
                square
              >
                <InputBase
                  value={date}
                  type="date"
                  className={classes.searchField}
                  onChange={(e) => {
                    e.persist();
                    setFilterCriteria((prev) => ({
                      ...prev,
                      date: e.target.value,
                    }));
                  }}
                />
              </Paper>
            </Grid>
          </Hidden>
          <Grid item xs={4} sm={2} className={classes.center}>
            <Paper
              elevation={2}
              className={classes.asset}
              variant="outlined"
              square
            >
              <InputBase
                value={workOrder}
                className={classes.searchField}
                onChange={(e) => {
                  e.persist();
                  setFilterCriteria((prev) => ({
                    ...prev,
                    workOrder: e.target.value,
                  }));
                }}
              />
            </Paper>
          </Grid>
          <Hidden only="xs">
            <Grid item sm={1} className={classes.center}>
              <Paper
                elevation={2}
                className={classes.asset}
                variant="outlined"
                square
              >
                <InputBase
                  value={location}
                  className={classes.searchField}
                  onChange={(e) => {
                    e.persist();
                    setFilterCriteria((prev) => ({
                      ...prev,
                      location: e.target.value,
                    }));
                  }}
                />
              </Paper>
            </Grid>
            <Grid item sm={1} className={classes.center}>
              <IconButton className={classes.iconButton} onClick={toggleOpen}>
                <DownloadIcon />
              </IconButton>
              <DownloadDialog
                open={open}
                onClose={toggleOpen}
                reports={checked}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Paper>
    </div>
  );
};

export default ItemRow;
