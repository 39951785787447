import { values, size } from "lodash";
import React, { useEffect } from "react";
import { Dialog, DialogContent, Fab } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { getUTTReports } from "actions/uttActions";

import UTTNew from "components/logs/uttNew";

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: 300,
  },
  fab: {
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    position: "absolute",
  },
}));

const UTTLog = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { uttReports } = useSelector((state) => state);
  const [openReport, setOpenReport] = React.useState(false);
  useEffect(() => {
    const fetchTests = () => dispatch(getUTTReports());
    fetchTests();
  }, []);
  const testList = values(uttReports);
  const Row = ({ index, style }) => {
    const { report, inspector } = testList[index];
    return (
      <div className={index % 2 ? "ListItemOdd" : "ListItemEven"} style={style}>
        {`${report} ${inspector}`}
      </div>
    );
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent className={classes.content}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              className="List"
              height={height}
              itemCount={size(testList)}
              itemSize={35}
              width={width}
            >
              {Row}
            </FixedSizeList>
          )}
        </AutoSizer>
        <Fab className={classes.fab} onClick={() => setOpenReport(true)}>
          <Add />
        </Fab>
      </DialogContent>
      <UTTNew open={openReport} onClose={() => setOpenReport(false)} />
    </Dialog>
  );
};

export default UTTLog;
