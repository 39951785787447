import { isEqual } from "lodash";
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  TextField,
  Paper,
  Grid,
  Button,
  IconButton,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import { object } from "yup";

import { useConfirm } from "components/confirm";
import BOMList from "components/codes/bomList";
import TaskList from "components/codes/taskList";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
  },
  input: {
    // marginBottom: theme.spacing(1),
  },
  description: {},
  iconButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

const Code = ({ open, onClose, code }) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const formatCost = (num) => {
    const trimmed = Math.round(num * Math.pow(10, 2)) / Math.pow(10, 2);
    return trimmed * 100;
  };
  const closeDialog = (values) => {
    isEqual(code, values)
      ? onClose()
      : confirm({
          description: "There are unsaved changes, do you still want to exit?",
        })
          .then(onClose)
          .catch();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={
        code || {
          code: "",
          description: "",
          bookPrice: 0,
        }
      }
      validationSchema={object().shape({
        //bookPrice: number().integer()
      })}
      onSubmit={(values) => console.log(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (
        <Dialog
          open={open}
          onClose={() => closeDialog(values)}
          fullWidth
          maxWidth="md"
        >
          <Form>
            <DialogTitle>
              Subcategory Code
              <IconButton
                onClick={() => closeDialog(values)}
                className={classes.iconButton}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item md={2} sm={12} xs={12}>
                    <TextField
                      name="code"
                      value={values.code}
                      onChange={handleChange}
                      label="Code"
                      fullWidth
                      variant="outlined"
                      className={classes.input}
                    />
                  </Grid>
                  <Grid item md={8} sm={12} xs={12}>
                    <TextField
                      name="description"
                      value={values.description}
                      multiline
                      onChange={handleChange}
                      label="Description"
                      fullWidth
                      variant="outlined"
                      className={classes.input && classes.description}
                    />
                  </Grid>
                  <Grid item md={2} sm={12} xs={12}>
                    <TextField
                      name="bookPrice"
                      value={values.bookPrice / 100}
                      type="number"
                      onChange={(e) => {
                        setFieldValue("bookPrice", formatCost(e.target.value));
                      }}
                      label="Book Price"
                      fullWidth
                      variant="outlined"
                      className={classes.input}
                      onBlur={handleBlur}
                      error={Boolean(touched.bookPrice && errors.bookPrice)}
                      helperText={touched.bookPrice && errors.bookPrice}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <BOMList />
              <TaskList />
            </DialogContent>
            <DialogActions>
              {!isEqual(code, values) && (
                <Button type="submit" color="primary">
                  Save
                </Button>
              )}
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default Code;
