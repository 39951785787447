import { map, uniq, includes, isEqual, pickBy, chain } from "lodash";
import React, { useEffect, useState, Fragment } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import { List, ListItem, ListItemText, ListSubheader } from "@material-ui/core";
import { lightBlue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { VariableSizeList } from "react-window";
import { fetchCodes } from "actions";

import Code from "components/codes/codesShow";
import Admin from "components/admin/adminMain";

const useStyles = makeStyles((theme) => ({
  list: {
    //minHeight: 80,
    height: "calc(95vh - 158px)",
    overflow: "auto",
    alignItems: "top",
  },
  listText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& p": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    maxWidth: "90%",
  },
  cost: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(1),
  },
  lastItem: {
    // marginTop: 35,
    zIndex: 2,
  },
  subheader: {
    transform: "translateY(-5px)",
    textAlign: "center",
    paddingBottom: 40,
    paddingTop: 5,
    paddingLeft: 0,
    paddingRight: 0,
    "& div": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineHeight: "2.3em",
      fontWeight: "bold",
      width: "100%",
      height: 35,
      backgroundColor: lightBlue[100],
    },
  },
}));

const MainList = ({ terms, search }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const [openCode, setOpenCode] = useState(null);
  const authUser = useSelector((state) => state.authUser);
  const isAdmin = includes(authUser.role, "ADMIN");
  const inventoryCodes = useSelector((state) => state.codes);
  // Combine terms and the search bar text from the filter component
  const filterTerms = [...terms, search];
  const filteredCodes = pickBy(
    inventoryCodes,
    ({ description, code, category }, codeId) => {
      const searchString = (category + description + code).toLowerCase();
      return isEqual(
        uniq(
          map(filterTerms, (term) => includes(searchString, term.toLowerCase()))
        ),
        [true]
      );
    }
  );
  //add the code Id to the pricebook code object and organize the array by category
  const codesBeforeRowHeight = chain(filteredCodes)
    .map((code, codeId) => ({ ...code, codeId }))
    .sortBy("category")
    .value();
  //add the row height to the object **This extra map adds to the expense of rendering but can't be done when I add the code id
  //because I don't have access to the preceding object.  I could do it all with a for loop if rendering gets too expensive.
  const codes = map(codesBeforeRowHeight, (o, index) => {
    const subRow =
      index === 0 ||
      (codesBeforeRowHeight[index + 1] &&
        o.category !== codesBeforeRowHeight[index - 1].category);
    return { ...o, rowHeight: subRow ? 70 : 35 };
  });

  useEffect(() => dispatch(fetchCodes()), [dispatch]);

  const Row = ({ index, style, subheaderRow }) => {
    const { description, codeId, code, bookPrice, rowHeight } = codes[index];
    const newCat =
      index === 0 ||
      (codes[index + 1] && codes[index].category !== codes[index - 1].category);
    return (
      <Fragment>
        {newCat && (
          <ListSubheader
            style={{ ...style, height: rowHeight }}
            className={classes.subheader}
            color="primary"
          >
            <div>{codes[index + 1].category}</div>
          </ListSubheader>
        )}
        <ListItem
          style={newCat ? { ...style, height: 35, marginTop: 35 } : style}
          className={newCat ? classes.lastItem : ""}
          onClick={() => setSelectedItem(codeId)}
          button
        >
          <ListItemText
            selected={codeId === selectedItem}
            onClick={() => setOpenCode(codeId)}
            primary={
              <p>
                <strong>{code}</strong>
                {` ${description}`}
              </p>
            }
            secondary={` $ ${bookPrice / 100}`}
            secondaryTypographyProps={{
              className: classes.cost,
            }}
            className={classes.listText}
          />
        </ListItem>
      </Fragment>
    );
  };
  const getItemSize = (index) => codes[index].rowHeight;
  const listRef = React.createRef();
  useEffect(() => {
    if (listRef.current) listRef.current.resetAfterIndex(0);
  }, [filterTerms]);
  return (
    <React.Fragment>
      <div onClick={() => listRef.current.resetAfterIndex(0)}>
        Results: {codes.length}
      </div>
      <List className={classes.list}>
        <AutoSizer>
          {({ height, width }) => (
            <VariableSizeList
              ref={listRef}
              className="List"
              height={height}
              itemCount={codes.length}
              itemSize={getItemSize}
              width={width}
            >
              {Row}
            </VariableSizeList>
          )}
        </AutoSizer>
      </List>
      <Code
        open={!!openCode}
        onClose={() => setOpenCode(null)}
        code={inventoryCodes[openCode]}
      />
      {isAdmin ? <Admin /> : null}
    </React.Fragment>
  );
};

export default MainList;
