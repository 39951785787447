import { values, size } from "lodash";
import React, { useEffect } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { getHydroTests } from "actions";

const useStyles = makeStyles(() => ({
  content: {
    minHeight: 300,
  },
}));

const HydroLog = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { hydrotests } = useSelector((state) => state);
  useEffect(() => {
    const fetchTests = () => dispatch(getHydroTests());
    fetchTests();
  }, []);
  const testList = values(hydrotests);
  const Row = ({ index, style }) => {
    const { chart, tester } = testList[index];
    return (
      <div className={index % 2 ? "ListItemOdd" : "ListItemEven"} style={style}>
        {`${chart} ${tester}`}
      </div>
    );
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent className={classes.content}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              className="List"
              height={height}
              itemCount={size(testList)}
              itemSize={35}
              width={width}
            >
              {Row}
            </FixedSizeList>
          )}
        </AutoSizer>
      </DialogContent>
    </Dialog>
  );
};

export default HydroLog;
