import { HYDRO_BY_ASSET, FETCH_HYDRO } from 'actions/hydroActions';

const INITIAL_STATE = {
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FETCH_HYDRO:
            return action.payload;
        case HYDRO_BY_ASSET:
            return action.payload;
        default:
            return state;
    }
}