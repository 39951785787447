import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));
const RebuildList = ({}) => {
  const classes = useStyles();
  const Row = ({ index, style }) => {
    return (
      <div className={index % 2 ? "ListItemOdd" : "ListItemEven"} style={style}>
        Row {index}
      </div>
    );
  };
  return (
    <AutoSizer className={classes.root}>
      {({ height, width }) => (
        <FixedSizeList
          className="List"
          height={height - 50}
          itemCount={1000}
          itemSize={35}
          width={width}
        >
          {Row}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

export default RebuildList;
