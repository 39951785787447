import { FETCH_TASKS, FETCH_TASK } from 'actions/taskActions';

const INITIAL_STATE = {
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FETCH_TASK:
            return { ...state, ...action.payload };
        case FETCH_TASKS:
            return action.payload;
        default:
            return state;
    }
}