import { includes } from "lodash";
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import Filter from "components/filter";
import RebuildList from "components/logs/rebuildList";
import CreateForm from "components/logs/rebuildFormCreate";

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: 400,
  },
  iconButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

const RebuildLog = ({ open, onClose }) => {
  const classes = useStyles();
  const user = useSelector((state) => state.authUser);
  const [create, setCreate] = React.useState(false);
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        Rebuild Log
        <IconButton onClick={onClose} className={classes.iconButton}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Filter>
          <RebuildList />
        </Filter>
        <CreateForm open={create} onClose={() => setCreate(false)} />
      </DialogContent>
      <DialogActions>
        {user && includes(user.role, "ADMIN") && (
          <Button onClick={() => setCreate(true)}>New Form</Button>
        )}
        <Button color="primary">Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RebuildLog;
