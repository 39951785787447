import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(3),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    textAlign: "center",
  },
  title: {
    flex: "1 1 100%",
  },
  header: {
    "& th": { fontWeight: "bold" },
  },
}));

const BOMList = ({}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.root} variant="dense">
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Bills of Material
        </Typography>
        <IconButton>
          <AddIcon />
        </IconButton>
      </Toolbar>
      <Table size="small">
        <TableHead>
          <TableRow className={classes.header}>
            <TableCell>BOM</TableCell>
            <TableCell>OEM</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Standard</TableCell>
            <TableCell>MSI</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default BOMList;
