import {
  mapValues,
  mapKeys,
  pickBy,
  capitalize,
  map,
  includes,
  reverse,
  startsWith,
} from "lodash";
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { CSVLink } from "react-csv";

import { getNonconformanceReports } from "actions";

import NonconformanceItem from "components/logs/nonconformanceItem";
import ItemRow from "components/logs/nonconformanceListItemRow";
import ItemHeaderRow from "components/logs/nonconformanceListItemHeaderRow";

const NonconformanceList = ({ terms, search }) => {
  const dispatch = useDispatch();
  const [editIndex, setEditIndex] = useState(-1);
  const [checked, setChecked] = useState({});
  const [allChecked, setAllChecked] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState({
    date: "",
    report: "",
    asset: "",
    status: "",
    workOrder: "",
    location: "",
  });
  const reports = useSelector((state) => state.nonconformanceReports);

  const pickReports = pickBy(reports, (report) => {
    const emptySearch = !includes(
      map(filterCriteria, (fc) => fc.length > 0),
      true
    );
    if (emptySearch) return true;
    const shouldInclude = includes(
      map(filterCriteria, (_searchValue, searchField) => {
        const fieldValue = capitalize(report[searchField]);
        const searchValue = capitalize(_searchValue);
        if (searchField === "asset") return startsWith(fieldValue, searchValue);
        return includes(fieldValue, searchValue);
      }),
      false
    );
    return !shouldInclude;
  });
  const filteredReports = map(pickReports, (report, reportId) => {
    return { ...report, reportId };
  });
  const items = reverse(
    map(filteredReports, (item, itemId) => ({ ...item, itemId }))
  );
  useEffect(() => {
    const fetchReports = () => {
      dispatch(getNonconformanceReports());
    };
    fetchReports();
  }, []);
  const Row = ({ index, style }) => {
    return (
      <ItemRow
        style={style}
        item={items[index]}
        edit={() => setEditIndex(index)}
        checked={checked}
        setChecked={setChecked}
      />
    );
  };
  const handleCheckAll = () => {
    const checkAll = mapValues(
      mapKeys(items, (v) => v.report),
      (v) => true
    );
    setChecked(allChecked ? {} : checkAll);
    setAllChecked((prev) => !prev);
  };
  return (
    <Fragment>
      <ItemHeaderRow
        setFilterCriteria={setFilterCriteria}
        filterCriteria={filterCriteria}
        handleCheckAll={handleCheckAll}
        allChecked={allChecked}
        checked={mapValues(checked, (r,report) => reports && reports[report])}
      />
      <AutoSizer style={{ marginTop: 4 }}>
        {({ height, width }) => {
          return (
            <FixedSizeList
              className="List"
              height={height - 90}
              itemCount={items.length}
              itemSize={45}
              width={width}
            >
              {Row}
            </FixedSizeList>
          );
        }}
      </AutoSizer>
      {editIndex > -1 ? (
        <NonconformanceItem
          editNCReportId={items[editIndex] && items[editIndex].reportId}
          closeEdit={() => setEditIndex(-1)}
        />
      ) : null}
    </Fragment>
  );
};

export default NonconformanceList;
