import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import Filter from "components/filter";

import NonconformanceItem from "components/logs/nonconformanceItem";
import NonconformanceList from "components/logs/nonconformanceList";

import { toggleDialog } from "actions";

const useStyles = makeStyles((theme) => ({
  content: {
    height: "calc(80vh - 30px)",
    overflow: "hidden",
    marginBottom: theme.spacing(2),
  },
  list: {},
}));

const NonconformanceLog = ({ asset }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { nonconformance } = useSelector((state) => state.dialogs);
  return (
    <Dialog
      open={nonconformance || false}
      onClose={() => dispatch(toggleDialog({ nonconformance: false }))}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Nonconformance Log</DialogTitle>
      <DialogContent className={classes.content}>
        <Filter default={asset}>
          <NonconformanceList />
        </Filter>
      </DialogContent>
      <DialogActions>
        <NonconformanceItem assetNum={asset} />
      </DialogActions>
    </Dialog>
  );
};

export default NonconformanceLog;
