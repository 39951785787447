import React from "react";
import {
  Grid,
  Avatar,
  Paper,
  Typography,
  Hidden,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { deepOrange } from "@material-ui/core/colors";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    alignItems: "center",
  },
  paper: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    display: "flex",
  },
  report: {
    fontSize: ".9rem",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wo: {
    fontSize: ".7rem",
  },
  avatar: {
    height: 26,
    width: 26,
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  checkbox: {
    padding: 0,
  },
}));

const ItemRow = ({ item, style, edit, checked, setChecked }) => {
  const classes = useStyles();
  const { date, report, asset, status, workOrder, location, pdfURL } = item;
  const displayDate = `${date.slice(5, 7)}/${date.slice(8, 10)}/${date.slice(
    0,
    4
  )}`;

  return (
    <div style={style} className={classes.root}>
      <Paper elevation={1} square className={classes.paper}>
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          className={classes.checkbox}
          checked={checked[report] || false}
          onChange={() =>
            setChecked((p) => ({ ...p, [report]: p[report] ? null : true }))
          }
        />
        <Grid container spacing={2} onClick={edit}>
          <Grid item xs={4} sm={2} className={classes.center}>
            <Paper
              elevation={2}
              className={classes.asset}
              variant="outlined"
              square
            >
              {asset}
            </Paper>
          </Grid>
          <Hidden only="xs">
            <Grid item sm={2} className={classes.center} zeroMinWidth>
              <Typography variant="button">
                {pdfURL ? (
                  <a href={pdfURL} target="_blank" rel="noopener noreferrer">
                    {report}
                  </a>
                ) : (
                  report
                )}
              </Typography>
            </Grid>
          </Hidden>
          <Grid item item xs={4} sm={2} className={classes.center}>
            {status !== "closed" ? (
              <Typography
                color={status === "open" ? "primary" : "secondary"}
                variant="button"
              >
                {status}
              </Typography>
            ) : null}
          </Grid>
          <Hidden only="xs">
            <Grid item sm={2} className={classes.center}>
              {displayDate}
            </Grid>
          </Hidden>
          <Grid item xs={4} sm={2} className={classes.center}>
            {workOrder && (
              <Typography
                className={classes.wo}
              >{`WO ${workOrder}`}</Typography>
            )}
          </Grid>
          <Hidden only="xs">
            <Grid item sm={2} className={classes.center}>
              <Avatar
                alt={location.toUpperCase()}
                src="image.jpg"
                // variant="square"
                className={classes.avatar}
                color="primary"
              />
            </Grid>
          </Hidden>
        </Grid>
      </Paper>
    </div>
  );
};

export default ItemRow;
