import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { indigo } from "@material-ui/core/colors";
import { AppBar, Paper, Tabs, Tab, Box, Typography } from "@material-ui/core";

import Filter from "components/filter";
import AssetsList from "components/assets/assetsList";
import CodesList from "components/codes/codesList";
import Logs from "components/logs/logsMain";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 768,
    margin: "0 auto 15px",
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    color: indigo[700],
    textAlign: "center",
    margin: 15,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

const Main = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Typography component="h1" variant="h5" className={classes.header}>
        Gulf-Pro Serialized Inventory
      </Typography>
      <Paper className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            variant="fullWidth"
            indicatorColor="primary"
          >
            <Tab label="Inventory" {...a11yProps(0)} />
            <Tab label="Price Book" {...a11yProps(1)} />
            <Tab label="Logs" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Filter>
            <AssetsList />
          </Filter>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Filter>
            <CodesList />
          </Filter>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Logs />
        </TabPanel>
      </Paper>
    </div>
  );
};

export default Main;
