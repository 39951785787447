import { database } from 'firebase_config';


export const FETCH_UTT = 'FETCH_UTT';

const uttReports = database.ref('utt/reports');
// const utt = database.ref('utt');

export function getUTTReports() {
    return dispatch => uttReports.limitToLast(100).once('value', snap => {
        dispatch({
            type: FETCH_UTT,
            payload: snap ? snap.val() : {}
        })
    })
}
