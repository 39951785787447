import { includes, map } from "lodash";
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import { makeStyles } from "@material-ui/core/styles";

import { fetchTasks } from "actions/taskActions";

const useStyles = makeStyles((theme) => ({
  create: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

const TaskLink = ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.authUser);
  const tasks = useSelector((state) => state.tasks);
  const isAdmin = includes(authUser.role, "ADMIN");
  const [openLink, setOpenLink] = useState(false);
  const toggleLink = () => setOpenLink((prevState) => !prevState);
  useEffect(() => {
    dispatch(fetchTasks());
  }, [fetchTasks]);
  return (
    <Fragment>
      <IconButton onClick={toggleLink}>
        <AddIcon />
      </IconButton>
      <Dialog open={openLink} onClose={toggleLink}>
        <DialogTitle>
          Link Tasks
          <IconButton className={classes.create}>
            <CreateIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <List>
            {map(tasks, (task, taskId) => {
              const { task_name } = task;
              return (
                <ListItem>
                  <ListItemText primary={task_name} />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default TaskLink;
