import { FETCH_NC_REPORT, FETCH_NC_REPORTS } from 'actions/nonconformanceActions';

const INITIAL_STATE = {
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FETCH_NC_REPORT:
            return { ...state, ...action.payload };
        case FETCH_NC_REPORTS:
            return action.payload;
        default:
            return state;
    }
}