import { database } from 'firebase_config';

export const FETCH_TASK = 'FETCH_TASK';
export const FETCH_TASKS = 'FETCH_TASKS';

const pmitp = database.ref('pmitp');

export function fetchTask(id) {
    return dispatch => pmitp.child(`tasks/${id}`).on('value', snapshot => {
        dispatch({
            type: FETCH_TASK,
            payload: snapshot ? snapshot.val() : {}
        })
    })
}

export function fetchTasks() {
    return dispatch => pmitp.child('tasks').on('value', snapshot => {
        dispatch({
            type: FETCH_TASKS,
            payload: snapshot ? snapshot.val() : {}
        })
    })
}