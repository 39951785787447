import React, { useEffect, useCallback, useState } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import { searchHydrotest, toggleDialog } from "actions";

import AssetHydro from "components/assets/assetHydro.jsx";
import NonconformanceLog from "components/logs/nonconformanceLog.jsx";

const useStyles = makeStyles((theme) => ({
  context: {
    border: "solid 1px #ccc",
    display: "inline-block",
    margin: "5px",
    color: "#000",
    fontFamily: "sans-serif",
    cursor: "pointer",
    fontSize: 12,
  },
}));

// const useContextMenu = () => {
//   const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
//   const [show, setShow] = useState(false);

//   const handleContextMenu = useCallback(
//     (event) => {
//       event.preventDefault();
//       setAnchorPoint({ x: event.pageX, y: event.pageY });
//       setShow(true);
//     },
//     [setShow, setAnchorPoint]
//   );

//   const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);

//   useEffect(() => {
//     document.addEventListener("click", handleClick);
//     document.addEventListener("contextmenu", handleContextMenu);
//     return () => {
//       document.removeEventListener("click", handleClick);
//       document.removeEventListener("contextmenu", handleContextMenu);
//     };
//   });
//   return { anchorPoint, show };
// };

const CustomContext = ({ toggle }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const openNC = useSelector((state) => state.dialogs.nonconformance);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const [asset, setAsset] = React.useState(null);
  const menuListener = (event) => {
    event.preventDefault();
    const boldAssetEl = event.target.querySelector("strong");
    const boldAsset = boldAssetEl && boldAssetEl.innerHTML;
    setAnchorEl({ left: event.pageX, top: event.pageY });
    setAsset(boldAsset);
    setShow(true);
  };
  const clickListener = (event) => {
    if (event.target === anchorEl) {
      return;
    }
    setAsset(null);
    setShow(false);
  };
  React.useEffect(() => {
    if (toggle && toggle.point) {
      setAnchorEl(toggle.point);
      setShow(Boolean(toggle));
      setAsset(toggle.boldAsset);
    }
  }, [toggle]);
  React.useEffect(() => {
    document.addEventListener("contextmenu", menuListener);
    document.addEventListener("click", clickListener);
  }, []);
  const ref = React.createRef();
  const toggleNCOpen = useCallback(() =>
    dispatch(toggleDialog({ nonconformance: true }))
  );
  return (
    <React.Fragment>
      <Menu
        className={classes.context}
        open={show}
        anchorPosition={anchorEl}
        anchorReference="anchorPosition"
        keepMounted
        placement="bottom-start"
      >
        <MenuItem onClick={() => setOpen(true)} ref={ref}>
          Hydrotest Data
        </MenuItem>

        <MenuItem onClick={toggleNCOpen} ref={ref}>
          Nonconformance Report
        </MenuItem>
      </Menu>
      <AssetHydro open={open} onClose={() => setOpen(false)} asset={asset} />
      {openNC && <NonconformanceLog asset={asset} />}
    </React.Fragment>
  );
};

export default CustomContext;
