import { FETCH_CODES } from 'actions';

const INITIAL_STATE = {
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FETCH_CODES:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}