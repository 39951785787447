import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { database } from "firebase_config";

function UTTNew({ open, onClose }) {
  const [scanPlanOptions, setScanPlanOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const ref = database.ref("utt/scans");
      ref.on("value", (snapshot) => {
        const data = snapshot.val();
        const options = Object.entries(data).map(([key, value]) => ({
          key,
          title: value.title,
        }));
        setScanPlanOptions(options);
      });
    };

    fetchData();

    return () => {
      database.ref("utt/scans").off();
    };
  }, []);

  const initialValues = {
    inspector: "",
    date: "",
    report: "",
    scanPlans: [""],
  };

  const validationSchema = Yup.object({
    inspector: Yup.string().required("Required"),
    date: Yup.string().required("Required"),
    report: Yup.string().required("Required"),
    scanPlans: Yup.array().of(
      Yup.string().required("Please select a scan plan to add an item")
    ),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    console.log(values);
    setSubmitting(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"My Dialog Title"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This is a simple example of a Material-UI v4 Dialog component with a
          Formik form.
        </DialogContentText>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched, values }) => (
            <Form>
              <Field
                as={TextField}
                name="inspector"
                label="Inspector"
                fullWidth
                margin="dense"
                error={touched.inspector && !!errors.inspector}
                helperText={touched.inspector && errors.inspector}
              />
              <Field
                as={TextField}
                name="date"
                label="Date"
                fullWidth
                margin="dense"
                error={touched.date && !!errors.date}
                helperText={touched.date && errors.date}
              />
              <Field
                as={TextField}
                name="report"
                label="Report"
                fullWidth
                margin="dense"
                error={touched.report && !!errors.report}
                helperText={touched.report && errors.report}
              />
              <FieldArray name="scanPlans">
                {({ push, remove }) => (
                  <div>
                    {values.scanPlans.map((_, index) => (
                      <div key={index}>
                        {index === 0 && (
                          <DialogContentText style={{ color: "red" }}>
                            Please select a scan plan to add an item
                          </DialogContentText>
                        )}
                        <Field
                          as={TextField}
                          select
                          name={`scanPlans.${index}`}
                          label="Scan Plan"
                          fullWidth
                          margin="dense"
                          error={
                            touched.scanPlans &&
                            touched.scanPlans[index] &&
                            !!errors.scanPlans &&
                            !!errors.scanPlans[index]
                          }
                          helperText={
                            touched.scanPlans &&
                            touched.scanPlans[index] &&
                            errors.scanPlans &&
                            errors.scanPlans[index]
                          }
                        >
                          {scanPlanOptions.map(({ key, title }) => (
                            <MenuItem key={key} value={key}>
                              {title}
                            </MenuItem>
                          ))}
                        </Field>
                        {index !== 0 && (
                          <Button
                            onClick={() => remove(index)}
                            color="secondary"
                          >
                            Remove
                          </Button>
                        )}
                      </div>
                    ))}
                    <Button onClick={() => push("")} color="primary">
                      Add another scan plan
                    </Button>
                  </div>
                )}
              </FieldArray>
              <DialogActions>
                <Button onClick={onClose} color="primary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  autoFocus
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default UTTNew;
