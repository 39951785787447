import React, { Fragment, useState } from "react";
import clsx from "clsx";
import { Fab, Tooltip } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import AssignmentIcon from "@material-ui/icons/Assignment";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import CircleIcon from "@material-ui/icons/Brightness1";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabSmall: {
    position: "absolute",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  fabUp: {
    transform: `translate(${-theme.spacing(0)}px, ${-theme.spacing(8)}px)`,
    transition: "transform 0.3s",
  },
  fabMid: {
    transform: `translate(${-theme.spacing(6)}px, ${-theme.spacing(6)}px)`,
    transition: "transform 0.3s",
  },
  fabLeft: {
    transform: `translate(${-theme.spacing(8)}px, ${-theme.spacing(0)}px)`,
    transition: "transform 0.3s",
  },
}));

const Admin = () => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const toggleFab = () => setHover((prevState) => !prevState);
  return (
    <Fragment>
      <Tooltip title="Tasks">
        <Fab
          size="small"
          onClick={toggleFab}
          className={clsx({
            [classes.fabSmall]: true,
            [classes.fabUp]: hover,
          })}
        >
          <AssignmentIcon />
        </Fab>
      </Tooltip>
      <Tooltip title="Bill of Materials">
        <Fab
          size="small"
          onClick={toggleFab}
          className={clsx({
            [classes.fabSmall]: true,
            [classes.fabMid]: hover,
          })}
        >
          <FormatListNumberedIcon />
        </Fab>
      </Tooltip>
      <Fab
        size="small"
        onClick={toggleFab}
        className={clsx({
          [classes.fabSmall]: true,
          [classes.fabLeft]: hover,
        })}
      >
        <CircleIcon />
      </Fab>
      <Tooltip title="Settings Menu">
        <Fab
          className={classes.fab}
          onClick={toggleFab}
          // onMouseLeave={() => setTimeout(() => setHover(false), 200)}
        >
          <SettingsIcon />
        </Fab>
      </Tooltip>
    </Fragment>
  );
};

export default Admin;
