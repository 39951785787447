import React, {
  Fragment,
  useContext,
  createContext,
  useState,
  useCallback,
} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ConfirmContext = createContext();

export const ConfirmationDialog = ({ open, options, onCancel, onConfirm }) => {
  const {
    title,
    description,
    confirmationText,
    cancellationText,
    dialogProps,
  } = options;

  return (
    <Dialog fullWidth {...dialogProps} open={open} onClose={onCancel}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {description && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onCancel}>{cancellationText}</Button>
        <Button onClick={onConfirm} color="secondary" variant="contained">
          {confirmationText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const useConfirm = () => {
  const confirm = useContext(ConfirmContext);
  return confirm;
};

const _defaultOptions = {
  title: "Are you sure?",
  description: "",
  confirmationText: "Ok",
  cancellationText: "Cancel",
  dialogProps: {},
};

export const ConfirmProvider = ({ children, defaultOptions = {} }) => {
  const [options, setOptions] = useState({
    ..._defaultOptions,
    ...defaultOptions,
  });
  const [resolveReject, setResolveReject] = useState([]);
  const [resolve, reject] = resolveReject;

  const confirm = useCallback(
    (options = {}) => {
      return new Promise((resolve, reject) => {
        setOptions({ ..._defaultOptions, ...defaultOptions, ...options });
        setResolveReject([resolve, reject]);
      });
    },
    [defaultOptions]
  );

  const handleClose = useCallback(() => {
    setResolveReject([]);
  }, []);

  const handleCancel = useCallback(() => {
    reject && reject();
    handleClose();
  }, [reject, handleClose]);

  const handleConfirm = useCallback(() => {
    resolve();
    handleClose();
  }, [resolve, handleClose]);

  return (
    <Fragment>
      <ConfirmContext.Provider value={confirm}>
        {children}
      </ConfirmContext.Provider>
      <ConfirmationDialog
        open={resolveReject.length === 2}
        options={options}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </Fragment>
  );
};
