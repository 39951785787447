import { map, includes } from "lodash";
import { database } from "firebase_config";
import React, { useState, useEffect, Fragment } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Link,
  Fab,
  Button,
  Grid,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  Switch,
  Radio,
  RadioGroup,
  LinearProgress,
  Collapse,
} from "@material-ui/core";

import { Formik, Form, useFormikContext } from "formik";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import { grey } from "@material-ui/core/colors";
import { useSelector, useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
import { object, string } from "yup";

import { useConfirm } from "components/confirm";

import {
  setNonconformanceReport,
  getReportNumber,
  addNCPhotos,
  deleteNCReport,
} from "actions";

const useStyles = makeStyles((theme) => ({
  add: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  subheader: {
    backgroundColor: grey[400],
    margin: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    textAlign: "center",
  },
  link: {
    marginRight: theme.spacing(1),
  },
  progress: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const PhotoDropzone = ({ values, handleChange, setFieldValue, pics }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [progress, setProgress] = useState(null);
  var picsArr = values[pics] || [];
  return progress ? (
    <Grid item xs={12}>
      <LinearProgress
        className={classes.progress}
        value={progress}
        variant="determinate"
      />
    </Grid>
  ) : (
    <Fragment>
      <Grid item xs={4}>
        <Dropzone
          accept={["image/*", "application/pdf"]}
          onDrop={(acceptedFiles) => {
            const cb = (response, data) => {
              switch (response) {
                case "progress":
                  setProgress(data);
                  break;
                case "error":
                  console.error(data);
                  break;
                case "complete":
                  picsArr.push(data);
                  setFieldValue(pics, picsArr);
                  setProgress(null);
                  // dispatch(
                  //     setNonconformanceReport(
                  //         {
                  //             ...values,
                  //             [pics]: picsArr
                  //         },
                  //         () => setProgress(null))
                  // );
                  break;
                default:
                  break;
              }
            };
            dispatch(addNCPhotos(acceptedFiles[0], cb));
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button color="primary">Attach Photo/File</Button>
              </div>
            </section>
          )}
        </Dropzone>
      </Grid>
      <Grid item xs={8}>
        {map(picsArr, ({ url, name }) => {
          return (
            <Link className={classes.link} href={url} target="_blank" key={url}>
              {name}
            </Link>
          );
        })}
      </Grid>
    </Fragment>
  );
};

const CustomerSpecific = () => {
  const { setFieldValue, values, handleChange } = useFormikContext();

  const [dbId, setDBId] = useState("");
  useEffect(() => {
    const fetchInfo = async () => {
      database.ref(`logs/nonconformance/getRTMSJobInfo`).off();
      const infoRef = database.ref(
        `logs/nonconformance/getRTMSJobInfo/${dbId}`
      );
      const rtmsInfoTrigger = infoRef.update({
        timestamp: Date.now(),
        asset: values.asset,
        uid: dbId,
      });
      infoRef.on("value", (snapshot) => {
        const { customer, workOrder, deliveryTicket, uid } = snapshot
          ? snapshot.val()
          : {};
        if (uid !== dbId) return;
        setFieldValue("customer", customer || "");
        setFieldValue("workOrder", workOrder || "");
        setFieldValue("deliveryTicket", deliveryTicket || "");
      });
    };
    if (dbId) fetchInfo();
  }, [dbId]);

  const rtmsDBUpdate = (e) => {
    const { checked } = e.target;
    if (!checked && dbId)
      database.ref(`logs/nonconformance/getRTMSJobInfo/${dbId}`).off();
    if (checked) {
      setDBId(uuidv4());
      // database.ref(`logs/nonconformance/reports/getJob/${dbId}`)
    }
    return;
  };
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Switch
            checked={values.customerSpecific}
            onChange={(e) => {
              e.persist();
              handleChange(e);
              rtmsDBUpdate(e);
            }}
            name="customerSpecific"
          />
        }
        label="Customer Specific"
      />
    </FormGroup>
  );
};

const NonconformanceItem = ({
  open,
  onClose,
  assetNum,
  editNCReportId,
  closeEdit,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const editNCReport = useSelector(
    (state) => state.nonconformanceReports[editNCReportId]
  );
  const [assetNumber, setAssetNumber] = useState(assetNum);
  const [show, setShow] = useState(open || false);
  const { firstName, lastName, uid, role } = useSelector(
    (state) => state.authUser
  );
  const { nonconformanceReportNumber } = useSelector((state) => state);
  const { asset, description } = useSelector(
    (state) => state.assets[assetNumber]
  ) || { asset: "", description: "" };
  const isEdit = Boolean(editNCReportId);
  const isAllowed = includes(role, "QHSE Manager", "ADMIN");
  const toggleShow = () => {
    return isEdit ? closeEdit() : setShow((prev) => !prev);
  };
  const today = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = ("0" + (now.getMonth() + 1)).slice(-2);
    const day = ("0" + now.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    if (!editNCReport && show) {
      dispatch(getReportNumber());
    }
  }, [show]);
  return (
    <Fragment>
      {isEdit ? null : (
        <Fab color="primary" className={classes.add} onClick={toggleShow}>
          <AddIcon />
        </Fab>
      )}
      <Formik
        enableReinitialize={!isEdit}
        initialValues={
          editNCReport || {
            asset,
            description,
            date: today(),
            by: `${firstName} ${lastName}`,
            uid,
            report: nonconformanceReportNumber || "",
            customerSpecific: false,
            customer: "",
            workOrder: "",
            deliveryTicket: "",
            activity: "",
            activityReport: "",
            issue: "",
            actionsTaken: "",
            pics: [],
            disposition: "other",
            repairVendor: "",
            repairPO: "",
            repairMisc: "",
            CPAR: "",
            costType: "none",
            cost: 0,
            repairDocs: [],
            billingMisc: "",
            repairPics: [],
            notes: "",
            status: "open",
            location: "",
            updated: Date.now(),
          }
        }
        validationSchema={object().shape({
          asset: string().required(),
          description: string().required(),
          location: string().required(),
          issue: string().required(),
          by: string().required(),
        })}
        onSubmit={(values, actions) => {
          const cb = () => {
            actions.resetForm();
            toggleShow();
          };
          const newValues = { ...values, updated: Date.now() };
          return dispatch(setNonconformanceReport(newValues, cb));
        }}
      >
        {(formikBag) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            handleBlur,
            resetForm,
            setValues,
          } = formikBag;
          const closeDialog = () => {
            toggleShow();
            resetForm();
          };
          const fillDescription = (e) => {
            e.persist();
            const asset = e.target.value;
            if (!asset || asset.length < 3) return;
            database
              .ref("serialized_assets/assets")
              .child(asset)
              .child("description")
              .once("value", (snap) => {
                if (snap.val()) {
                  return setFieldValue("description", snap.val());
                }
                return setFieldValue("description", "");
              });
          };
          return (
            <Dialog open={isEdit || show} onClose={toggleShow}>
              <Form>
                <DialogTitle>Out-of-Tolerance Report</DialogTitle>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="asset"
                        label="Asset"
                        value={values.asset}
                        onChange={(e) => {
                          handleChange(e);
                          if (values.customerSpecific && values.customer) {
                            setValues({
                              ...values,
                              asset: e.target.value,
                              customer: "",
                              workOrder: "",
                              deliveryTicket: "",
                              customerSpecific: false
                            });
                          }
                          fillDescription(e);
                        }}
                        fullWidth
                        variant="outlined"
                        onBlur={handleBlur}
                        error={Boolean(touched.asset && errors.asset)}
                        helperText={touched.asset && errors.asset}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="date"
                        label="Date"
                        type="date"
                        value={values.date}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="description"
                        label="Description"
                        value={values.description}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        helperText={touched.description && errors.description}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="by"
                        label="Initiated By"
                        value={values.by}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        onBlur={handleBlur}
                        error={Boolean(touched.by && errors.by)}
                        helperText={touched.by && errors.by}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="report"
                        label="Report Number"
                        value={values.report}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="location"
                        label="Location"
                        value={values.location}
                        onChange={handleChange}
                        fullWidth
                        select
                        variant="outlined"
                        onBlur={handleBlur}
                        error={Boolean(touched.location && errors.location)}
                        helperText={touched.location && errors.location}
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="houma">Houma</MenuItem>
                        <MenuItem value="broussard">Broussard</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomerSpecific />
                    </Grid>
                    <Collapse in={values.customerSpecific}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <TextField
                            name="customer"
                            label="Customer"
                            value={values.customer}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="workOrder"
                            label="Work Order"
                            value={values.workOrder}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="deliveryTicket"
                            label="Delivery Ticket"
                            value={values.deliveryTicket}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.subheader}>
                      <Typography variant="subtitle1">
                        Nonconformity Info
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="issue"
                        label="Describe the Issue"
                        value={values.issue}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        multiline
                        minRows={2}
                        onBlur={handleBlur}
                        error={Boolean(touched.issue && errors.issue)}
                        helperText={touched.issue && errors.issue}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="actionsTaken"
                        label="Describe the Steps Taken"
                        value={values.actionsTaken}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        multiline
                        minRows={2}
                      />
                    </Grid>
                    <PhotoDropzone pics="pics" {...formikBag} />
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.subheader}>
                      <Typography variant="subtitle1">Disposition</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl component="fieldset" disabled={!isAllowed}>
                        <RadioGroup
                          row
                          name="disposition"
                          value={values.disposition}
                          onChange={(e) => {
                            if (e.target.value !== "other") {
                              setFieldValue("status", "pending");
                            }
                            handleChange(e);
                          }}
                        >
                          <FormControlLabel
                            value="inHouse"
                            control={<Radio size="small" />}
                            label="Fixed In-House"
                          />
                          <FormControlLabel
                            value="asIs"
                            control={<Radio size="small" />}
                            label="Accepted as-is"
                          />
                          <FormControlLabel
                            value="sentForRepair"
                            control={<Radio size="small" />}
                            label="Sent for Repair"
                          />
                          <FormControlLabel
                            value="junked"
                            control={<Radio size="small" />}
                            label="Junked"
                          />
                          <FormControlLabel
                            value="other"
                            control={<Radio size="small" />}
                            label="Other"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.subheader}>
                      <Typography variant="subtitle1">Cost Info</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          name="costType"
                          value={values.costType}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="repair"
                            control={<Radio size="small" />}
                            label="Repair"
                          />
                          <FormControlLabel
                            value="replace"
                            control={<Radio size="small" />}
                            label="Replace"
                          />
                          <FormControlLabel
                            value="none"
                            control={<Radio size="small" />}
                            label="None"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Collapse in={values.costType !== "none"}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="repairVendor"
                            label="Vendor"
                            value={values.repairVendor}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="repairPO"
                            label="PO"
                            value={values.repairPO}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="repairMisc"
                            label="Notes"
                            value={values.repairMisc}
                            onChange={handleChange}
                            fullWidth
                            multiline
                            minRows={2}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                    <PhotoDropzone pics="repairPics" {...formikBag} />
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.subheader}>
                      <Typography variant="subtitle1">Misc. Notes</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="notes"
                        label="Notes"
                        value={values.notes}
                        onChange={handleChange}
                        fullWidth
                        multiline
                        minRows={2}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  {isAllowed ? (
                    <Grid container spacing={1}>
                      <Grid item xs={12} className={classes.subheader}>
                        <Typography variant="subtitle1">
                          Report Status
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="status"
                          label="Report Status"
                          value={values.status}
                          onChange={(e) => {
                            if (
                              e.target.value === "closed" &&
                              values.disposition === "junked"
                            ) {
                              return confirm({
                                description:
                                  "You are closing a report with a junked item. " +
                                  "By closing, you are verifying the item has been removed from inventory and junked in all databases",
                              })
                                .then(() => handleChange(e))
                                .catch(() =>
                                  setFieldValue("status", values.status)
                                );
                            }
                            handleChange(e);
                          }}
                          fullWidth
                          select
                          variant="outlined"
                        >
                          <MenuItem value="open">Open</MenuItem>
                          <MenuItem value="pending">Pending</MenuItem>
                          <MenuItem value="closed">Closed</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  ) : null}
                </DialogContent>
                <DialogActions>
                  <Button color="primary" onClick={closeDialog}>
                    Cancel
                  </Button>
                  {isAllowed ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        confirm({
                          description:
                            "Are you sure you want to delete this report?",
                        }).then(() =>
                          dispatch(
                            deleteNCReport(editNCReport.report, closeDialog)
                          )
                        );
                      }}
                    >
                      Delete
                    </Button>
                  ) : null}
                  <Button type="submit" variant="contained" color="primary">
                    {editNCReport ? "Update" : "Sumbit"}
                  </Button>
                </DialogActions>
              </Form>
            </Dialog>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default NonconformanceItem;
