import {
  map,
  uniq,
  includes,
  isEqual,
  pickBy,
  reverse,
  omit,
  values,
} from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import { CSVLink } from "react-csv";
import {
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Grid,
  Chip,
  FormControlLabel,
  FormControl,
  FormLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Badge,
} from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import { FixedSizeList } from "react-window";
import { useLongPress } from "components/Longpress";

import { fetchItems } from "actions";

import CustomContext from "components/customContext";

const useStyles = makeStyles((theme) => ({
  list: {
    //minHeight: 80,
    height: "calc(95vh - 158px)",
    overflow: "auto",
  },
  listText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& span": {
      //fontSize: '0.5em',
    },
  },
  test: {
    backgroundColor: "red",
  },
  results: {
    textAlign: "right",
    zIndex: 100,
  },
  fieldset: {
    width: "100%",
    paddingLeft: theme.spacing(4),
  },
  accordion: {
    marginTop: theme.spacing(-0.65),
  },
  accordionSummary: {
    minHeight: 30,
    padding: 0,
    "& div": {
      marginTop: 0,
      marginBottom: 0,
    },
  },
}));

const MainList = ({ terms, search }) => {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState({});
  const [filterCriteria, setFilterCriteria] = useState({
    code: true,
    asset: true,
    description: true,
  });
  const [multiple, setMultiple] = useState(false);
  const [toggle, setToggle] = useState(null);
  const inventoryItems = useSelector((state) => state.assets);
  const filterTerms = [...terms, search];
  const filteredItems = pickBy(
    inventoryItems,
    ({ description, code }, itemId) => {
      const searchString = (
        (filterCriteria.asset ? itemId : "") +
        " " +
        (filterCriteria.description ? description : "") +
        " " +
        (filterCriteria.code ? code : "")
      ).toLowerCase();
      return isEqual(
        uniq(
          map(filterTerms, (term) => includes(searchString, term.toLowerCase()))
        ),
        [true]
      );
    }
  );
  const items = reverse(
    map(filteredItems, (item, itemId) => ({ ...item, itemId }))
  );
  const dispatch = useDispatch();
  useEffect(() => dispatch(fetchItems()), [dispatch]);
  useEffect(() => {
    document.addEventListener("keydown", (event) => {
      if (event.keyCode === 16) return setMultiple(true);
    });
    document.addEventListener("keyup", (event) => {
      if (event.keyCode === 16) return setMultiple(false);
    });
  }, []);
  const longPressEvent = useLongPress(
    (e) => {
      const point = { left: e.touches[0].clientX, top: e.touches[0].clientY };
      const boldAssetEl = e.target.querySelector("strong");
      const boldAsset = boldAssetEl && boldAssetEl.innerHTML;
      setToggle({ point, boldAsset });
    },
    () => {
      console.log(items[0]);
    }
  );
  const Row = ({ index, style }) => {
    const { description, itemId, code, status } = items[index];
    return (
      <ListItem
        style={style}
        {...longPressEvent}
        disabled={status === "junked"}
        onClick={(event) => {
          return selectedItem[itemId]
            ? setSelectedItem(omit(selectedItem, itemId))
            : setSelectedItem({ ...selectedItem, [itemId]: true });
        }}
        button
        selected={selectedItem[itemId]}
      >
        <ListItemText
          primary={
            <p>
              {status === "out-of-service" ? (
                <Tooltip title="See Nonconformance Log">
                  <Badge
                    badgeContent={
                      <WarningIcon
                        style={{ color: orange[500], fontSize: "1.6em" }}
                      />
                    }
                  >
                    <strong>{itemId}</strong>
                  </Badge>
                </Tooltip>
              ) : (
                <strong>{itemId}</strong>
              )}
              {` (${code}) ${description}`}
            </p>
          }
          className={classes.listText}
        />
      </ListItem>
    );
  };
  const csvLink = React.createRef();
  return (
    <React.Fragment>
      <CustomContext toggle={toggle} />
      <div className={classes.results}>
        Results:
        <CSVLink
          data={values(filteredItems)}
          filename={"filteredAssets.csv"}
          target="_blank"
          ref={csvLink}
        >
          {items.length}
        </CSVLink>
      </div>
      <FormControl component="fieldset" className={classes.fieldset}>
        <Accordion elevation={0} className={classes.accordion}>
          <AccordionSummary className={classes.accordionSummary}>
            <FormLabel component="legend">
              Filter results by:
              {map(filterCriteria, (incl, name) => {
                if (incl) {
                  return <Chip size="small" label={name} key={name} />;
                }
              })}
            </FormLabel>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  checked={filterCriteria.asset}
                  onClick={() =>
                    setFilterCriteria({
                      ...filterCriteria,
                      asset: !filterCriteria.asset,
                    })
                  }
                  control={<Checkbox color="primary" />}
                  label="Asset"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  checked={filterCriteria.description}
                  onClick={() =>
                    setFilterCriteria({
                      ...filterCriteria,
                      description: !filterCriteria.description,
                    })
                  }
                  control={<Checkbox color="primary" />}
                  label="Description"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  checked={filterCriteria.code}
                  onClick={() =>
                    setFilterCriteria({
                      ...filterCriteria,
                      code: !filterCriteria.code,
                    })
                  }
                  control={<Checkbox color="primary" />}
                  label="Pricebook Code"
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </FormControl>
      <List className={classes.list}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              className="List"
              height={height}
              itemCount={items.length}
              itemSize={35}
              width={width}
            >
              {Row}
            </FixedSizeList>
          )}
        </AutoSizer>
      </List>
    </React.Fragment>
  );
};

export default MainList;
