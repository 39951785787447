import React, { useState, Fragment } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";

import HydroLog from "components/logs/hydroLog";
import NonconformanceLog from "components/logs/nonconformanceLog";
import RebuildLog from "components/logs/rebuildMain";
import UTTLog from "components/logs/uttLog";

import { toggleDialog } from "actions";

const useStyles = makeStyles(() => {});

const Logs = () => {
  const [open, setOpen] = useState("");
  const dispatch = useDispatch();
  return (
    <Fragment>
      <List>
        <ListItem button onClick={() => setOpen("damages")}>
          <ListItemAvatar>
            <Avatar alt="Damages" src="">
              D
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Damages" />
        </ListItem>
        <ListItem button onClick={() => setOpen("utt")}>
          <ListItemAvatar>
            <Avatar alt="UTT" src="">
              UT
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="UTT" />
        </ListItem>
        <ListItem button>
          <ListItemAvatar>
            <Avatar alt="MPI" src="">
              MT
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="MPI" />
        </ListItem>
        <ListItem button onClick={() => setOpen("rebuild")}>
          <ListItemAvatar>
            <Avatar alt="Rebuild" src="">
              RB
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Rebuild" />
        </ListItem>
        <ListItem button>
          <ListItemAvatar>
            <Avatar alt="Maintenance" src="">
              M
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Maintenance" />
        </ListItem>
        <ListItem button onClick={() => setOpen("hydro")}>
          <ListItemAvatar>
            <Avatar alt="Hydro" src="">
              H
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Hydro" />
        </ListItem>
        <ListItem
          button
          onClick={() => dispatch(toggleDialog({ nonconformance: true }))}
        >
          <ListItemAvatar>
            <Avatar alt="Nonconformance" src="">
              NC
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Nonconformance" />
        </ListItem>
      </List>
      <UTTLog open={open === "utt"} onClose={() => setOpen("")} />
      <HydroLog open={open === "hydro"} onClose={() => setOpen("")} />
      <RebuildLog open={open === "rebuild"} onClose={() => setOpen("")} />
      <NonconformanceLog />
    </Fragment>
  );
};

export default Logs;
