// import { pickBy, includes, flattenDeep, values, pick, map } from 'lodash';
import { database, auth } from 'firebase_config';
// import { CSVLink, CSVDownload } from "react-csv";

import { setNonconformanceReport, getReportNumber, addNCPhotos, getNonconformanceReports, deleteNCReport, createNCPDFs, createZipUrl, getZipUrl, cancelZipListen } from 'actions/nonconformanceActions';
import { searchHydrotest, queryHydro, getHydroTests } from 'actions/hydroActions';

export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';
export const FETCH_ITEMS = 'FETCH_ITEMS';
export const FETCH_CODES = 'FETCH_CODES';
export const DIALOGS = 'DIALOGS';

const users = database.ref('users/users');
const assets = database.ref('serialized_assets/assets');
const codes = database.ref('serialized_assets/codes');

// =================================
// ======Nonconformance Actions=====
// =================================

export { setNonconformanceReport, getReportNumber, addNCPhotos, getNonconformanceReports, deleteNCReport, createNCPDFs, createZipUrl, getZipUrl, cancelZipListen };

// =================================
// ======Hydro Actions=====
// =================================

export { searchHydrotest, queryHydro, getHydroTests };

// =================================
// =========Dialog Actions==========
// =================================

export function toggleDialog(dialog) {
    return dispatch => {
        dispatch({
            type: DIALOGS,
            payload: dialog
        })
    }
}

// =================================
// =====Inventory Item Actions======
// =================================

export function fetchItems() {
    return dispatch => assets.on('value', snap => {
        dispatch({
            type: FETCH_ITEMS,
            payload: snap ? snap.val() : {}
        })
    })
}

// =================================
// =====Inventory Code Actions======
// =================================

export function fetchCodes() {
    return dispatch => codes.on('value', snap => {
        dispatch({
            type: FETCH_CODES,
            payload: snap ? snap.val() : {}
        })
    })
}

// =================================
// ==========Auth Actions===========
// =================================

export function signIn(values, cb) {
    const { email, password } = values;
    return () => auth.signInWithEmailAndPassword(email, password)
    .then(res=>{
            cb({type: 'User', res});
            })
        .catch(res=>cb({type: 'Error', res}))
}


export function verifyAuth() {
    return dispatch=>{
        const key = 'firebase:authUser:AIzaSyCWm0ZmQfgmqEqGuFFQqfkewU58d2V-14c:[DEFAULT]';
        auth.onAuthStateChanged(user=>{
            if (user) {
                users.child(user.uid).once('value').then(snapshot=>{
                    const dbUser = snapshot.val()
                    const userString = JSON.stringify(user)
                    window.localStorage.setItem(key, userString)
                    console.log('logged in')
                    dispatch({
                        type: AUTH_USER,
                        payload: {...user, ...dbUser}
                    })
                })
                
            } else {
                window.localStorage.removeItem(key)
                dispatch({
                    type: UNAUTH_USER
                })
            }
        });
    };
}




