import { map, remove } from 'lodash';
import { database, storage } from 'firebase_config';

export const FETCH_NC_REPORT = 'FETCH_NC_REPORT';
export const FETCH_NC_REPORTS = 'FETCH_NC_REPORTS';
export const REPORT_NUMBER = 'REPORT_NUMBER';
export const FETCH_ZIPURLS = 'FETCH_ZIPURLS';

const nonconformanceReports = database.ref('logs/nonconformance/reports');
const nonconformanceReportNumber = database.ref('logs/nonconformance/reportNumber');
const assets = database.ref('serialized_assets/assets');

const nonconformanceImages = storage.ref('nonconformance/images');


export function setNonconformanceReport(values, cb) {
    const { report, disposition, asset, status } = values;
    const junked = disposition === 'junked' && status === 'closed';
    const pending = status === 'pending';
    const cleared = disposition !== 'junked' && status === 'closed';
    const update = () => nonconformanceReports.child(report).update(values).then(cb);
    const updateAssetStatus = status => {
        assets.child(asset).update({status})
            .then(update)
            .catch(error => console.error(error));
    }
    return () => {
        if (junked) return (updateAssetStatus('junked'));
        if (pending) return (updateAssetStatus('out-of-service'));
        if (cleared) return (updateAssetStatus('active'));
        return update();
    }
}

export function getNonconformanceReport(reportId) {
    return dispatch => {
        nonconformanceReports.child(reportId).on('value', snap => {
            dispatch({
                type: FETCH_NC_REPORT,
                payload: snap ? snap.val() : {}
            })
        })
    }
}
export function deleteNCReport(reportId, cb) {
    return dispatch => {
        const deleteReport = nonconformanceReports.child(reportId)
        deleteReport.once('value', async snapshot => {
            const val = await snapshot.val();
            const docs = { ...val.pics, ...val.repairPics };
            const { asset } = val;
            const silAsset = await assets.child(asset).once('value').then(snap => snap.val())
            const removeStatus = () => assets.child(silAsset.asset).update({status: ''});
            map(docs, ({filePath}) => {
                nonconformanceImages.child(filePath).delete();
                //Future: if file extension is an image, remove 200x200 thumbnail too
                //if ()
            })
            return silAsset && removeStatus();
        }).then(() => deleteReport.remove().then(cb))
        
    }
}

export function createNCPDFs(reports) {
    return () => {
            console.log({reports})
        map(reports, (r,reportId) => {
            const now = Date.now();
            console.log({r,reportId})
            return nonconformanceReports.child(`${reportId}/pdfUpdated`).set(now);
        })
    }
}

export function getZipUrl(zipId) {
    return dispatch => database.ref(`logs/nonconformance/zipUrls`).on('value', snap => {
        dispatch({
            type: FETCH_ZIPURLS,
            payload: snap ? snap.val() : {}
        })
    })
}

export function cancelZipListen() {
    return dispatch => database.ref(`logs/nonconformance/zipUrls`).off();
}

export function createZipUrl(zipId, reports) {
    const urls = map(reports, report => report.pdfURL || null)
    return () => database.ref(`logs/nonconformance/zipUrls/${zipId}`).set({working: true, urls})
}
export function getNonconformanceReports() {
    return dispatch => {
        nonconformanceReports.on('value', snap => {
            dispatch({
                type: FETCH_NC_REPORTS,
                payload: snap ? snap.val() : {}
            })
        })
    }
}

export function getReportNumber() {
    return dispatch => nonconformanceReportNumber.transaction(count => (count = count+1))
    .then((newCount) => {
        const val = newCount.snapshot.val()
        const newReport = (`nc_${val.toString(16)}`).toUpperCase()
        dispatch({
            type: REPORT_NUMBER,
            payload: newReport
        })
    })
}

export function addNCPhotos(file, cb) {
    const key = nonconformanceReports.push().key;
    const { name } = file;
    return () => {
        const filePath = `${key}/${name}`
        const uploadTask = nonconformanceImages.child(filePath).put(file);
        uploadTask.on('state_changed', snapshot => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          cb('progress', progress);
        },
        error => {
          cb('error',  error );
        },
        () => {
          //const fullPath = await uploadTask.snapshot.ref.getMetadata().then((metadata) => metadata.fullPath);
          uploadTask.snapshot.ref.getDownloadURL().then(url => {
            cb('complete', { url, name, filePath });
          });
        });
    }
}