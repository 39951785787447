import { combineReducers } from 'redux';
import authUser from 'reducers/authUser';
import assets from 'reducers/assets';
import codes from 'reducers/codes';
import hydrotests from 'reducers/hydrotests';
import affectedHydro from 'reducers/affectedHydro';
import tasks from 'reducers/tasks';
import nonconformanceReports from 'reducers/nonconformance';
import nonconformanceReportNumber from 'reducers/nonconformanceReportNumber';
import dialogs from 'reducers/dialogs';
import uttReports from 'reducers/uttReports';
import zipUrls from 'reducers/zipUrls';

export default combineReducers({
  authUser,
  assets,
  codes,
  hydrotests,
  affectedHydro,
  tasks,
  nonconformanceReports,
  nonconformanceReportNumber,
  dialogs,
  uttReports,
  zipUrls
})