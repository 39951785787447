
import { database } from 'firebase_config';

export const HYDRO_BY_ASSET = 'HYDRO_BY_ASSET';
export const FETCH_HYDRO = 'FETCH_HYDRO';

const hydroTestItems = database.ref('hydrotest/testItems');
const hydro = database.ref('hydrotest');

export function getHydroTests() {
    return dispatch => hydroTestItems.limitToLast(100).once('value', snap => {
        dispatch({
            type: FETCH_HYDRO,
            payload: snap ? snap.val() : {}
        })
    })
}

export function searchHydrotest(asset) {
    return dispatch => hydroTestItems.orderByChild('asset').equalTo(asset).once('value', snapshot => {
        dispatch({
            type: HYDRO_BY_ASSET,
            payload: snapshot ? snapshot.val() : null
        })
    })
}

export function queryHydro() {
//     return dispatch => {
//         hydro.child('tests').orderByChild('timestamp').startAt(1627737358000).once('value', s => {
//             const tests = s.val();
//             const results =  pickBy(tests, test => {
//                 const flat = map(flattenDeep(map(flattenDeep(values(test.holds)), hold => hold.intervals)), interval => interval.serial);
//                 const hasSensor = includes(flat, '0843463')
//                 return test.holds && hasSensor
                
//             });
//             dispatch({
//                 type: 'FETCH_CHARTS',
//                 payload: results
//             })
//         })
//     }
}