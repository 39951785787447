import { map, values } from "lodash";
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { searchHydrotest } from "actions";
const AssetHydro = ({ open, onClose, asset }) => {
  const dispatch = useDispatch();
  const hydrotests = useSelector((state) => state.hydrotests);
  const [selected, setSelected] = React.useState(asset);
  React.useEffect(() => {
    const search = () => dispatch(searchHydrotest(selected));
    search();
    setSelected(asset);
  }, [asset]);
  const date = (stamp) => {
    const now = new Date(stamp);
    const year = now.getFullYear();
    const month = ("0" + (now.getMonth() + 1)).slice(-2);
    const day = ("0" + now.getDate()).slice(-2);
    return `${month}/${day}/${year}`;
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Hydrotests for GPS{" "}
        {values(hydrotests)[0] && values(hydrotests)[0].asset}
      </DialogTitle>
      <DialogContent onClick={() => console.log(hydrotests, selected)}>
        <List>
          {map(hydrotests, (test, id) => {
            const { url, timestamp, tester, chart, pressure, associated } =
              test;
            return (
              <ListItem key={id} button component="a" href={url}>
                <ListItemText
                  primary={`${chart}${associated ? "*" : ""}`}
                  secondary={`${date(
                    timestamp
                  )} - ${tester} - ${pressure.toFixed()} psi`}
                />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
};

// const OpenHydro = React.forwardRef(({
//     asset
// }, ref) => {

//     return (
//         <React.Fragment>
//         <MenuItem onClick={() => setOpen(true)} ref={ref}>
//             Hydrotest Data
//         </MenuItem>
//         </React.Fragment>
//         )
// })

export default AssetHydro;
